import * as React from 'react';
import { Image, IImageProps } from '@fluentui/react/lib/Image';
import {  Link, Text } from '@fluentui/react';
import { FontWeights, ITextStyles } from '@fluentui/react';

// These props are defined up here so they can easily be applied to multiple Images.
// Normally specifying them inline would be fine.
const imageProps: Partial<IImageProps> = {
  // Show a border around the image (just for demonstration purposes)
  styles: props => ({ root: { border: '1px solid ' + props.theme.palette.neutralSecondary } }),
};
const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
export const InstallPluginStep = () => (
  <div>
    <Text variant="xLarge" styles={boldStyle}>
            Порядоку установки для программы Excel под Windows (без "Магазина надстроек")
    </Text>
    <p>
      <Text variant="large" styles={boldStyle}>
            1. Убедитесь, что ваша программа Excel соответствует минимальным требованиям
    </Text> 
    </p>
      
    <p>
      Для работы надстройки потребуется программа Excel версии не ниже <b>Версия 2102 (сборка 13801.20738)</b> 
    </p>
    <p>
      Убедится, что ваша версия программы Excel соответствует минимальным требованиям можно открыв меню "Файл", "Учётная запись", пункт "О программе Excel"
    </p>
    <Image {...imageProps} src="/assets/inst0.png"  alt="Версия программы" width={400} height={400} />
    <p>
      В случае если это не так обновите программу Excel.
    </p>
    <p>
      <Text variant="large" styles={boldStyle}>
        2. Скачайте манифест надстройки
    </Text>      
    </p>
    <p>
      Манифестом надстройки является файл в формате xml. Манифест доступен по ссылке: <b><Link href="/manifest.xml" download>manifest.xml </Link></b> .                 
    </p>      
    <p>
      Благодаря этому файлу программа Excel получает доступ к надстройке "Сметы и документы". 
    </p>
    <p>
      Скачайте манифест в любую удобную сетевую папку в вашей локальной сети.
    </p>
    <p>
          Для размещения манифеста можно использовать локальную папку на вашем компьютере. 
          В этом случае вам необходимо открыть сетевой доступ к этой папке. 
    </p>
    <p>
      <Text variant="large" styles={boldStyle}>
      3. Подключите надстройку к программе Excel
    </Text>      
    </p>
    <p>
      Откройте программу Excel
    </p>      
    <p>
      Откройте меню "Файл", "Параметры"
    </p>
    <p>
      Выберите пункт "Центр управления безопасностью" нажмите кнопку "Параметры центра управления безопасностью"
    </p>
    <Image {...imageProps} src="/assets/inst1.png"  alt="Выбрать каталог" width={600} height={400} />
    <p>
      Выбирете пункт "Доверенные каталоги надстроек"
    </p>
    <p>
      В поле "URL-адрес каталога" укажите путь к сетевой папке, где расположен манифест надстройки и нажмите кнопку "Добавить каталог"
    </p>
    <Image {...imageProps} src="/assets/inst2.png"  alt="Выбрать каталог" width={600} height={400} />
    <p>
      В списке ниже отметьте вашу папку и последовательно закройте окна последовательным нажатием кнопки "Ок"
    </p>
    <p>
      Закройте программу Excel и откройте снова.
    </p>
    <p>
      <Text variant="large" styles={boldStyle}>
      3. Запустите надстройку
    </Text>      
    </p>
    <p>
      На панеле управления перейдите на закладку "Вставка"
    </p> 
    <p>
    <Image {...imageProps} src="/assets/inst3.png"  alt="Вставка" width={400} height={200} />
    </p>     
    <p>
      Нажмите кнопку "Получить надстройки"
    </p>
    <p>
      Выбирете закладку "ОБЩАЯ ПАПКА"
    </p>
    <p>
    <Image {...imageProps} src="/assets/inst4.png"  alt="Общая папка" width={400} height={400} />
    </p>
    <p>
      Выбирете пункт "Сметы и документы" и нажмите кнопку "Добавить"
    </p>
    <p>
      На панеле управления появятся соответствующие кнопки.
    </p>
    <p>
      <b>Надстройка установлена!</b>
    </p>

  </div>
);
