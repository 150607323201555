import React from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import './App.css';
import { InstallPluginStep } from './InstallPluginStep';

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15, padding: 10 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: '960px',
    margin: '0 auto',
    padding: '0px',
    color: '#605e5c',
  },
};

export const App: React.FunctionComponent = () => {
  return (
    
    <div>
      
      <Stack horizontalAlign="center" verticalAlign="center" verticalFill styles={stackStyles} tokens={stackTokens}>
      <br></br>
      <img src="/assets/logo-filled.png" alt="logo"  width={200} height={200}/>

      <div>
        <Text variant="xxLarge" styles={boldStyle}>
          Надстройка "Сметы и документы" для Excel
        </Text>
        <Stack horizontalAlign="start" verticalAlign="center" tokens={stackTokens}>
          <Text variant="large">Надстройка "Сметы и документы" для Excel позволяет создавать и редактировать проектно-сметную документацию без использования специализированных сметных программ.</Text>
          <Text variant="xLarge" styles={boldStyle}></Text>
        </Stack>
      </div>
      <InstallPluginStep></InstallPluginStep>
      <Text variant="large" styles={boldStyle}>
        Помощь
      </Text>
      <Text variant="large">Если у вас остались вопросы свяжитесь с нами.</Text>
      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <Link href="mailto:mail@htmlacademy.ru">tosmeta@yandex.ru</Link>

      </Stack>
    </Stack>
    </div>
  );
};
